interface Props {
  children?: any;
  className?: string;
  disabled?: boolean;
  label?: string;
  labelClass?: string;
  name: string;
  required?: boolean;
  placeholder?: string;
  type?: string;
  value?: string | number;
  max?: string | number;
  min?: string | number;
  maxLength?:any;
  wrapperClass: string;
  onBlur?: (event: any) => void;
  onChange?: (event: any) => void;
  inputProps?: any;
  autoComplete?: any;
}

function InputField({
  children,
  className,
  disabled,
  name,
  placeholder,
  required,
  type,
  value,
  label,
  labelClass,
  wrapperClass,
  onBlur,
  onChange,
  autoComplete,
  ...restProps
}: Props) {
  return (
    <div className={wrapperClass}>
      {label && (
        <label className={labelClass} htmlFor={name}>
          {label}
          {required && <sub>*</sub>}
        </label>
      )}
      <input
        autoComplete={autoComplete}
        className={className}
        disabled={disabled}
        id={name}
        name={name}
        placeholder={placeholder}
        type={type}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        {...restProps}
      />
      {children || null}
    </div>
  );
}

InputField.defaultProps = {
  className: "",
  disabled: false,
  label: "",
  labelClass: "",
  placeholder: "",
  required: false,
  type: "text",
  value: "",
  wrapperClass: "input-wrapper",
  onBlur: () => {},
  onChange: () => { },
  min: "",
  max: "",
  maxLength:"",
  format:'mm/dd/yyyy',
  autoComplete:"off"
};

export default InputField;
