const messages = {
  IS_REQUIRED: (name: string) => `${name} is required.`,
  PASSWORD_VALIDATION: 'Password must be at least 8 characters and must include at least one upper case letter, one lower case letter, and one numeric digit and one special character.',
  CONFIRM_PASSWORD: 'Both password need to be the same.',
  EMAIL_VALIDATION: 'The email id you entered is incorrect.',
  LINKEDIN: 'The linkedIn url you entered is incorrect.',
  MOBILE_NO: 'The phone number you entered is incorrect.',
  NAME: (field: string) => `${field} can only contain alphabetic characters`
};

export default messages;
