export function titleCase(value: string, splitBy: string = "_") {
  if (!value) return "";
  const temp = value.split(splitBy);

  return temp.map((val) => val.slice(0, 1) + val.slice(1)).join(" ");
}

export const addThreeDots = (value: string, len: number = 30) => {
  if (!value || value.length < len) return titleCase(value, " ");
  return titleCase(value).slice(0, len) + "...";
};

export function pluralize(role: string) {
  if (role.endsWith("ch")) {
    return `${role}es`;
  } else if (role.endsWith("y")) {
    return `${role.slice(0, role.length - 1)}ies`;
  }
  return `${role}s`;
}

export function getFullName(data: any = {}) {
  if (!data) return data;
  return addThreeDots(`
   ${data.firstName || data.firstname || ""}
    ${data.lastName || data.lastname || ""}`);
}
