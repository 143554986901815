import { createAsyncThunk } from "@reduxjs/toolkit";

import { getDataApi, putDataApi } from "../../api/methods";

export const fetchSessionsList = createAsyncThunk(
  "admin/sessions",
  (payload: any) => getDataApi({ path: "admin/sessions", data: payload })
);

// export const fetchcalenderSechdule = createAsyncThunk(
//   "admin/schedule/admin/{coachId}/slots",
//   (payload: any) =>
//     getDataApi({ path: "admin/sessions", data: payload })
// );

export const cancelSession = createAsyncThunk(
  "schedule/admin/cancel",
  ({ scheduleId, payload }: any) => {
    putDataApi({
      path: `schedule/admin/cancel/${scheduleId}`,
      data: payload,
    });
  }
);

export const fetchSlotList = createAsyncThunk(
  "schedule/admin",
  ({ coachId, payload }: any) =>
    getDataApi({
      path: `schedule/admin/${coachId}/slots`,
      baseUrl: "v2",
      data: payload,
    })
);

export const rescheduleSessions = createAsyncThunk(
  "schedule/admin/reschedule",
  ({ scheduleId, payload }: any) => {
    putDataApi({
      path: `schedule/admin/reschedule/${scheduleId}`,
      data: payload,
    });
  }
);
