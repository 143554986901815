const regex = {
  PASSWORD: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,}$)/,
  EMAIL: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
  LINKEDIN: /^(http(s)?:\/\/)?([\w]+\.)?linkedin\.com\/(pub|in|profile)/gm,
  MOBILE_NO: /^[0-9]{9,15}$/,
  MOBILE_NO2: /^[0-9]{5,16}$/,
  NAME: /^[a-zA-Z\s]+$/,
};

export default regex;
