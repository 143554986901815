interface Props {
  message?: string;
}

function ValidationMessage({ message }: Props) {
  if (!message) return null;

  return <span className="validation-error">{message}</span>;
}

export default ValidationMessage;
