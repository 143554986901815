import React from "react";
import { Link, useLocation } from "react-router-dom";

import { ModalView } from "..";
import { ADMIN_USER_TYPE } from "../../../helpers";
import { PERMISSION_TYPE } from "../../../pages/sub-admin/Types";
import { clearReduxState } from "../../../redux/features/authSlice";
import { setIsPageRefresh } from "../../../redux/features/commonSlice";
import { useAppDispatch, useAppSelector } from "../../custom-hooks";
import { accountMenu, menuList, staticMenuList } from "./Config";
import { menuType } from "./Types";

const Sidebar = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const { type, permissions } = useAppSelector((state: any) => state.auth);

  const [selectedMenuIndex, setSelectedMenuIndex] = React.useState<number>(-1);
  const [isOpenLogoutModal, setIsOpenLogoutModal] =
    React.useState<boolean>(false);

  const handleToggleLogoutModal = React.useCallback((value: boolean) => {
    setIsOpenLogoutModal(value);
  }, []);

  const handleOnLogout = React.useCallback(() => {
    dispatch(clearReduxState());
  }, [dispatch]);

  const sideBarMenuList = React.useMemo(() => {
    let finalList: Array<menuType> = [];
    if (type === ADMIN_USER_TYPE) {
      finalList = menuList.slice();
    } else {
      finalList = permissions
        .map((item: PERMISSION_TYPE) => {
          const role = menuList.find(
            (menu: menuType) =>
              item.module.toLowerCase() === menu.permission?.toLowerCase()
          );
          return role || null;
        })
        .filter((menu: menuType) => menu);
    }
    finalList.splice(1, 0, accountMenu);
    return [...finalList, ...staticMenuList];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type]);

  React.useEffect(() => {
    const index = sideBarMenuList.findIndex(
      (menu) => menu.pathname && location.pathname.includes(menu.pathname)
    );
    setSelectedMenuIndex(index);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  return (
    <div className="side-bar">
      <ul className="side-menu">
        {sideBarMenuList.map((menu: menuType, index: number) => {
          if (menu) {
            return (
              <li
                key={index}
                onClick={() => {
                  if (menu.name === "Logout") {
                    if (!isOpenLogoutModal) {
                      handleToggleLogoutModal(true);
                    }
                  }
                  if (menu.pathname === location.pathname) {
                    dispatch(setIsPageRefresh(true));
                  }
                }}
              >
                <Link
                  to={menu.pathname || "#"}
                  className={selectedMenuIndex === index ? "active" : ""}
                >
                  <img className="selected" src={menu.icon} alt={menu.name} />
                  {menu.name}
                </Link>
              </li>
            );
          }

          return null;
        })}
      </ul>

      {/* logout-modal */}
      <ModalView
        isOpen={isOpenLogoutModal}
        title="Logout"
        header="Are you sure want to Logout?"
        wrapperClassName="logout-modal"
        onToggle={() => handleToggleLogoutModal(false)}
        onSubmit={handleOnLogout}
      />
    </div>
  );
};

export default Sidebar;
