import { Logo4X } from '../../../assets/images';

/** Auth screens view */
const AuthLeftSideView = () => {
  return (
    <div className="col-md-6 auth-left d-flex flex-center">
      <div className="left-inner text-center">
        <div className="top-sec">
          <img src={Logo4X} alt="Logo" />
          <h3>Maximize your career solution</h3>
        </div>
        <div className="bottom-sec">
          <p>© 2023 Career Truth</p>
        </div>
      </div>
    </div>
  );
};

export default AuthLeftSideView;
