import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
import './assets/styles/styles.scss';

import { LoaderView } from './common/components';
import { store } from './redux/store';
import RoutesWrapper from './router';

function App() {

  return (
    <Provider store={store}>
      <LoaderView />
      <ToastContainer />
      <BrowserRouter>
        <RoutesWrapper />
      </BrowserRouter>
    </Provider>
  );
}

export default App;
