import PhoneInput from 'react-phone-input-2';

import 'react-phone-input-2/lib/style.css';

import { MODULES } from '../../../helpers';
import InputField from '../input-field';

interface Props {
    disabled?: boolean;
    label?: string;
    labelClass?: string;
    required?: boolean;
    countryCode: string;
    value: string;
    placeholder: string;
    onChange: (event: any) => void;
    onBlur?: () => void;
    onCodeChange: (value: string) => void;
    onCodeBlur?: () => void;
    onSetDialCode?: (value: number) => void;
}

function PhoneNumber({ disabled, label, labelClass, required, value, countryCode, placeholder, onChange, onCodeChange, onCodeBlur, onBlur, onSetDialCode }: Props) {
    return <>
        {label &&
            <label className={labelClass} htmlFor={label}>
                {label}
                {required && <sub>*</sub>}
            </label>
        }
        <div className="phone-number-field">
            <PhoneInput
                country={countryCode ? countryCode.toLowerCase() : MODULES.PHONE_NUMBER.DEFAULT_COUNTRY_CODE}
                placeholder={placeholder}
                disabled={disabled}
                onBlur={onCodeBlur}
                onChange={(e, data: any) => {
                    if (data?.countryCode) {
                        onCodeChange(data.countryCode);
                    }
                    if (onSetDialCode && data?.dialCode) {
                        onSetDialCode(data.dialCode);
                    }
                }}
            />
            <InputField
                className='height'
                type='number'
                name='mobileNo'
                placeholder={placeholder}
                value={value}
                disabled={disabled}
                onBlur={onBlur}
                onChange={onChange}
            />
        </div>
    </>;
}

PhoneNumber.defaultProps = {
    disabled: false,
    label: '',
    labelClass: '',
    countryCode: MODULES.PHONE_NUMBER.DEFAULT_COUNTRY_CODE,
    required: false,
    onBlur: () => { },
    onChange: () => { },
    onCodeChange: () => { },
    onCodeBlur: () => { },
    onSetDialCode: () => { }
};

export default PhoneNumber;
