import { combineReducers } from 'redux';
import logger from 'redux-logger';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // Change to use localStorage
import { configureStore, Reducer, AnyAction } from '@reduxjs/toolkit';
import authSlice from './features/authSlice';
import commonSlice from './features/commonSlice';
import config from '../Config';

const persistConfig = {
  key: 'root',
  storage, // Now using localStorage
};

const appReducer = combineReducers({
  auth: persistReducer(persistConfig, authSlice),
  common: commonSlice,
});

const rootReducer: Reducer = (state: RootState, action: AnyAction) => {
  if (action.type === 'auth/clearReduxState') {
    // Clear localStorage when clearing Redux state
    localStorage.removeItem('persist:root');
    state = {} as RootState;
  }
  return appReducer(state, action);
};

export var store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => {
    if (config.ENV === 'dev') {
      return getDefaultMiddleware().concat(logger);
    } else {
      return getDefaultMiddleware();
    }
  },
});

export const persistor = persistStore(store);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;

// Inferred type: {auth: authState, common: commonState}
export type AppDispatch = typeof store.dispatch;
