import { Form, Formik } from 'formik';
import { Link } from "react-router-dom";

import { Logo4X } from "../../../assets/images";
import { DateFormat } from '../../third-party-instances';
import { ROUTES } from './../../../helpers';

const Header = () => {
    return (
        <Formik
            initialValues={{
                search: '',
            }}
            onSubmit={() => { }}
        >
            {() => (
                <Form>
                    <header className="header d-flex flex-center-between">
                        <div className="logo">
                            <Link to={ROUTES.DASHBOARD}>
                                <img src={Logo4X} alt="Logo" />
                            </Link>
                        </div>
                        <div className="header-left">
                            <h3>{DateFormat(new Date(), 'd MMM')}</h3>
                        </div>
                    </header>
                </Form>
            )}
        </Formik>
    );
};

export default Header;
